import React from "react"
import { graphql } from "gatsby"

import {
  Layout,
  Seo,
  HeaderImage,
  RichTextTickBullets,
  ButtonList,
  Button,
  SectionTextWithImage,
  Accordions,
} from "../components"
import { handleSectionBackground } from "../utils"

interface UseCasePage {
  location: {
    href: string
  }
  data: {
    contentfulPageUseCase: {
      header: {
        raw: string
      }
      headerImage: {
        gatsbyImageData: any
        title: string
      }
      icon: {
        file: {
          url: string
        }
      }
      name: string
      summary: string
      pageSections: any
      seo: {
        title: string
        titleTemplate: {
          extension: string
        }
        description: {
          description: string
        }
        image: {
          fixed: {
            src: string
          }
        }
      }
    }
  }
}

const UseCase = ({ data, location }: UseCasePage) => {
  const page = data.contentfulPageUseCase
  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderImage
        icon={page?.icon?.file.url}
        image={page.headerImage.gatsbyImageData}
        imageAlt={page.headerImage.title}
        heading={page.name}
      >
        <RichTextTickBullets variant="header-image" content={page.header} />
        <ButtonList>
          <Button
            style="primary"
            url={`${process.env.GATSBY_PATIENT_PORTAL}/sign-up`}
          >
            Sign up free
          </Button>
          <Button
            style="secondary"
            url="https://calendly.com/gotthetest/intro-call-demo"
          >
            Schedule demo
          </Button>
        </ButtonList>
      </HeaderImage>
      {page?.pageSections?.map((section: any, index: number) => {
        if (section.__typename === "ContentfulPageSectionTextWithImage") {
          return (
            <SectionTextWithImage
              key={section.contentful_id}
              contentful_id={section.contentful_id}
              backgroundColour={handleSectionBackground(
                section.backgroundColour,
                index % 2 === 0 ? "white" : "grey-lightest"
              )}
              heading={section.heading}
              content={section.content}
              image={section?.media?.media?.gatsbyImageData}
              imageAlt={section?.media?.media?.title}
              showImageFirst={section.showImageFirst}
              imageBlobBackground={section.imageBlobBackground}
              showSignUpButton={section.showSignUpButton}
              scheduleDemoButton={section.scheduleDemoButton}
              file={section?.media?.media?.file?.url}
              youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
            />
          )
        } else if (section.__typename === "ContentfulPageSectionAccordions") {
          return (
            <SectionTextWithImage
              key={section.contentful_id}
              contentful_id={section.contentful_id}
              backgroundColour={handleSectionBackground(
                section.backgroundColour,
                index % 2 === 0 ? "white" : "grey-lightest"
              )}
              heading={section.heading}
              content={section.content}
              image={section?.media?.media?.gatsbyImageData}
              imageAlt={section?.media?.media?.title}
              showImageFirst={section.showImageFirst}
              imageBlobBackground={section.imageBlobBackground}
              showSignUpButton={section.showSignUpButton}
              scheduleDemoButton={section.scheduleDemoButton}
              file={section?.media?.media?.file?.url}
              youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
              variant="accordions"
            >
              <Accordions accordions={section.accordions} />
            </SectionTextWithImage>
          )
        }
      })}
    </Layout>
  )
}

export default UseCase

export const pageQuery = graphql`
  query getUseCase($id: String!) {
    contentfulPageUseCase(contentful_id: { eq: $id }) {
      header {
        raw
      }
      name
      summary
      icon {
        file {
          url
        }
      }
      headerImage {
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      pageSections {
        ... on ContentfulPageSectionAccordions {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          accordions {
            heading
            body {
              raw
            }
            contentful_id
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
        ... on ContentfulPageSectionTextWithImage {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
      }
      seo {
        title
        titleTemplate {
          extension
        }
        description {
          description
        }
        image {
          fixed {
            src
          }
        }
      }
    }
  }
`
